<script>
import { ArrowRightIcon } from "vue-feather-icons";

import Navbar from "@/components/navbar";
import Services from "@/components/services";
import Features from "@/components/ditribution-feture";
import Pricing from "@/components/pricing";
import Team from "@/components/team";
import Blog from "@/components/blog";
import Contact from "@/components/contact";
import Footer from "@/components/footer";
import DisturabtionCard from "@/components/disturabtioncard";
// import counter_section from "@/components/counter-section";


export default {
    components: {
        // StarIcon,
        ArrowRightIcon,
        Navbar,
        Services,
        Features,
        Pricing,
        Team,
        Blog,
        Contact,
        Footer,
        DisturabtionCard,
        // counter_section,
    },
    data() {
        return {};
    },
};
</script>


<template>
    <div>
        <Navbar />
        <div v-scroll-spy>
            <div class="nav">
            </div> 
            <Pricing />
            <Contact />
            <Footer />
        </div>
    </div>
</template>


<style scoped>
.nav {
    height: 70px;
    background: #3dbeff;
}
</style>
